<template>
  <div class="box">
    <div class="flex between">
      <div class="titleBox">
        <!-- <h1>创变营销服务平台</h1> -->
        <img class="titleTop" src="../assets/imgs/topTitle.png" alt="">
        <p>企业构建数字化营销闭环</p>
        <img src="../assets/imgs/login/background.png" alt="" />
      </div>

      <div class="card" v-if="codeShow == 1">
        <el-tabs v-model="activeName" tab-position="bottom" stretch>
          <el-tab-pane name="first">
            <span class="f_weight" slot="label"
              ><i class="el-icon-full-screen f_weight"></i> 扫码登录</span
            >
            <div class="QR">
              <!-- <img src="../assets/imgs/logo.png" alt="" /> -->
              <div id="login_container"></div>
              <p>
                <i class="el-icon-full-screen f_weight"></i
                >打开微信扫一扫,立即注册或登录
              </p>
            </div>
          </el-tab-pane>
          <el-tab-pane label="密码登录" name="second">
            <span slot="label" class="f_weight"
              ><i class="el-icon-lock f_weight"></i> 密码登录</span
            >
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
              <el-form-item prop="name">
                <el-input
                  placeholder="请输入账号"
                  prefix-icon="el-icon-user"
                  v-model="ruleForm.name"
                ></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  placeholder="请输入密码"
                  show-password
                  prefix-icon="el-icon-lock"
                  v-model="ruleForm.password"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-checkbox v-model="remember">记住密码</el-checkbox>
              </el-form-item>
              <el-form-item>
                <button
                  type="button"
                  class="login"
                  @click="goLoginPaw('ruleForm')"
                >
                  立即登录
                </button>
              </el-form-item>

              <!-- <p class="forget">忘记密码</p> -->
            </el-form>
          </el-tab-pane>
          <!-- <el-tab-pane label="验证码登录" name="thirdly">
            <span slot="label" class="f_weight"
              ><i class="el-icon-circle-check f_weight"></i> 验证码登录</span
            >
            <el-form
              :model="ruleFormCode"
              :rules="rulesCode"
              ref="ruleFormCode"
            >
              <el-form-item prop="phone">
                <el-input
                  placeholder="请输入手机号"
                  prefix-icon="el-icon-mobile"
                  v-model="ruleFormCode.phone"
                ></el-input>
              </el-form-item>
              <el-form-item prop="code">
                <el-input
                  placeholder="请输入验证码"
                  prefix-icon="el-icon-lock"
                  v-model="ruleFormCode.code"
                >
                  <el-button
                    :disabled="disabled"
                    type="button"
                    slot="append"
                    @click="getCode(ruleFormCode)"
                    :loading="loadBtn"
                  >
                    {{ test }}
                  </el-button>
                </el-input>
              </el-form-item>
              <el-form-item>
                <button
                  type="button"
                  class="login"
                  @click="goLoginCode('ruleFormCode')"
                >
                  立即登录
                </button>
              </el-form-item>
            </el-form>
          </el-tab-pane> -->
        </el-tabs>
        <el-checkbox class="agreement" v-model="agreement"
          >我已阅读并同意
          <el-button type="text" @click="serve">用户协议</el-button>
        </el-checkbox>
        <el-dialog :visible.sync="dialogVisible">
          <div ref="file" class="preview"></div>
        </el-dialog>
      </div>
      <div class="codeCard" v-else-if="codeShow == 2">
        <h2>请完善您的信息</h2>
        <el-form :model="ruleFormCode2" :rules="rulesCode2" ref="ruleFormCode2">
          <el-form-item prop="companyName">
            <el-input
              placeholder="请输入公司名称"
              prefix-icon="el-icon-office-building"
              v-model="ruleFormCode2.companyName"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              placeholder="请设置密码"
              prefix-icon="el-icon-lock"
              v-model="ruleFormCode2.password"
            ></el-input>
          </el-form-item>
          <el-form-item prop="notarizePassword">
            <el-input
              placeholder="请确认密码"
              prefix-icon="el-icon-lock"
              v-model="ruleFormCode2.notarizePassword"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <button
              type="button"
              class="login"
              @click="goBackground('ruleFormCode2')"
            >
              立即登录
            </button>
          </el-form-item>
        </el-form>
      </div>

      <div class="codeCard" v-else-if="codeShow == 3">
        <h2>请完善您的信息</h2>
        <el-form :model="ruleFormCode3" :rules="rulesCode3" ref="ruleFormCode3">
          <el-form-item prop="phone">
            <el-input
              placeholder="请输入手机号"
              prefix-icon="el-icon-mobile"
              v-model="ruleFormCode3.phone"
            ></el-input>
          </el-form-item>
          <el-form-item prop="code">
            <el-input
              placeholder="请输入验证码"
              prefix-icon="el-icon-lock"
              v-model="ruleFormCode3.code"
            >
              <el-button
                :disabled="disabled"
                type="button"
                slot="append"
                @click="getCode(ruleFormCode3)"
                :loading="loadBtn"
              >
                {{ test }}
              </el-button>
            </el-input>
          </el-form-item>
          <el-form-item prop="companyName">
            <el-input
              placeholder="请输入公司名称"
              prefix-icon="el-icon-office-building"
              v-model="ruleFormCode3.companyName"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              placeholder="请设置密码"
              prefix-icon="el-icon-lock"
              show-password
              v-model="ruleFormCode3.password"
            ></el-input>
          </el-form-item>
          <el-form-item prop="notarizePassword">
            <el-input
              placeholder="请确认密码"
              prefix-icon="el-icon-lock"
              show-password
              v-model="ruleFormCode3.notarizePassword"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <button type="button" class="login" @click="scan('ruleFormCode3')">
              立即登录
            </button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script src='http://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js'></script>
<script>
import axios from "axios";
import {
  loginAccount,
  send,
  loginMobile,
  ImproveInformation,
  loginScan,
} from "../http/api";
import { renderAsync } from "docx-preview";

export default {
  name: "login",
  data() {
    return {
      agreement: true,
      remember: false,
      activeName: "first",
      dialogVisible: false,
      disabled: false,
      loadBtn: false,
      test: "获取验证码",
      codeShow: 1,
      ruleForm: {
        name: "",
        password: "",
      },
      rules: {
        name: [{ required: true, message: "请输入账号", trigger: "change" }],
        password: [
          { required: true, message: "请输入密码", trigger: "change" },
        ],
      },
      ruleFormCode: {
        phone: "",
        code: "",
      },
      ruleFormCode3: {
        phone: "",
        code: "",
        companyName: "",
        password: "",
        notarizePassword: "",
      },
      ruleFormCode2: {
        companyName: "",
        password: "",
        notarizePassword: "",
      },
      rulesCode: {
        phone: [{ required: true, message: "请输入手机号", trigger: "change" }],
        code: [{ required: true, message: "请输入验证码", trigger: "change" }],
      },
      rulesCode2: {
        companyName: [
          { required: true, message: "请输入公司名称", trigger: "change" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "change" },
        ],
        notarizePassword: [
          { required: true, message: "请确认密码", trigger: "change" },
        ],
      },
      rulesCode3: {
        phone: [{ required: true, message: "请输入手机号", trigger: "change" }],
        code: [{ required: true, message: "请输入验证码", trigger: "change" }],
        companyName: [
          { required: true, message: "请输入公司名称", trigger: "change" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "change" },
        ],
        notarizePassword: [
          { required: true, message: "请确认密码", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    // 协议弹窗
    serve() {
      this.dialogVisible = true;
      axios({
        url: "/deal.docx",
        responseType: "blob",
      }).then((res) => {
        renderAsync(res.data, this.$refs.file, null);
      });
    },
    // 用户协议
    async agreementFn() {
      if (!this.agreement) {
        this.$message.warning({ duration: 2000, message: "请先勾选用户协议!" });
        return false;
      } else {
        return true;
      }
    },
    // 验证码二次登录  完善信息
    goBackground(ruleFormCode2) {
      this.$refs[ruleFormCode2].validate((valid) => {
        if (valid) {
          if (
            this.ruleFormCode2.password == this.ruleFormCode2.notarizePassword
          ) {
            let data = {
              company_name: this.ruleFormCode2.companyName,
              password: this.ruleFormCode2.password,
              password_confirm: this.ruleFormCode2.notarizePassword,
            };
            ImproveInformation(data).then((res) => {
              if (res.code == 0) {
                this.$message.success({
                  duration: 2000,
                  message: "登录成功，即将进入后台!",
                });
              }
              localStorage.setItem("id", res.data.id);
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("platform", res.data.platform);
              setTimeout(() => {
                this.$router.push({
                  name: "background",
                  query: {
                    avatar: res.data.userinfo.avatar,
                    adUrl: res.data.platform[0],
                    edmUrl: res.data.platform[1],
                    contentUrl: res.data.platform[2],
                  },
                });
              }, 2000);
            });
          } else {
            this.$message.error({
              duration: 2000,
              message: "两次密码不一致",
            });
          }
        } else {
          return false;
        }
      });
    },
    // 密码登录
    goLoginPaw(ruleForm) {
      if (this.remember) {
        localStorage.setItem("paw", this.ruleForm.password);
        localStorage.setItem("name", this.ruleForm.name);
      } else {
        localStorage.removeItem("paw");
        localStorage.removeItem("name");
      }
      var data = {
        mobile: this.ruleForm.name,
        pwd: this.ruleForm.password,
      };
      this.agreementFn().then((res) => {
        if (res) {
          this.$refs[ruleForm].validate((valid) => {
            if (valid) {
              loginAccount(data).then((res) => {
                if (res.code == 0) {
                  localStorage.setItem("id", res.data.id);
                  localStorage.setItem("token", res.data.token);
                  localStorage.setItem(
                    "userinfo",
                    JSON.stringify(res.data.userinfo)
                  );
                  localStorage.setItem("platform", res.data.platform);
                  this.$message.success({
                    duration: 2000,
                    message: "登录成功!",
                  });
                  this.$router.push({
                    path: "/background",
                  });
                } else {
                  this.$message.error({ duration: 2000, message: res.message });
                }
              });
            } else {
              return false;
            }
          });
        }
      });
    },
    // 获取验证码
    getCode(ruleFormCode) {
      var reg = /^1[3-9]\d{9}$/;
      if (reg.test(ruleFormCode.phone)) {
        var data = {
          mobile: ruleFormCode.phone,
        };
        this.loadBtn = true;
        send(data).then((res) => {
          if (res.code == 0) {
            this.loadBtn = false;
            this.disabled = true;
            var num = 60;
            var time = setInterval(() => {
              num--;
              this.test = num;
              if (num == 0) {
                clearInterval(time);
                this.disabled = false;
                this.test = "获取验证码";
              }
            }, 1000);
          } else {
            this.loadBtn = false;
            this.$message.error({
              duration: 2000,
              message: res.message,
            });
          }
        });
      } else {
        this.$message.error({
          duration: 2000,
          message: "请输入正确的手机号!",
        });
      }
    },
    // 手机验证码
    goLoginCode(ruleFormCode) {
      this.agreementFn().then((res) => {
        if (res) {
          this.$refs[ruleFormCode].validate((valid) => {
            if (valid) {
              var data = {
                mobile: this.ruleFormCode.phone,
                code: this.ruleFormCode.code,
              };
              loginMobile(data).then((res) => {
                if (res.code == 0) {
                  this.$message.warning({
                    duration: 2000,
                    message: "请完善信息!",
                  });
                  localStorage.setItem("token", res.data.token);
                  this.codeShow = 2;
                } else {
                  this.$message.error({
                    duration: 2000,
                    message: res.message,
                  });
                }
              });
            } else {
              return false;
            }
          });
        }
      });
    },
    // 扫码登录的完善信息
    scan(ruleFormCode3) {
      this.$refs[ruleFormCode3].validate((valid) => {
        if (valid) {
          if (
            this.ruleFormCode3.password == this.ruleFormCode3.notarizePassword
          ) {
            let data = {
              mobile: this.ruleFormCode3.phone,
              code: this.ruleFormCode3.code,
              company_name: this.ruleFormCode3.companyName,
              password: this.ruleFormCode3.password,
              password_confirm: this.ruleFormCode3.notarizePassword,
            };
            ImproveInformation(data).then((res) => {
              if (res.code == 0) {
                localStorage.setItem("id", res.data.id);
                localStorage.setItem("token", res.data.token);
                localStorage.setItem(
                  "userinfo",
                  JSON.stringify(res.data.userinfo)
                );
                localStorage.setItem("platform", res.data.platform);
                this.$message.success({
                  duration: 2000,
                  message: "登录成功!",
                });

                this.$router.push({
                  path: "/background",
                });
              } else {
                this.$message.error({
                  duration: 2000,
                  message: res.message,
                });
              }
            });
          } else {
            this.$message.error({
              duration: 2000,
              message: "两次密码不一致",
            });
          }
        } else {
          return false;
        }
      });
    },
  },
  mounted() {
    if (localStorage.getItem("paw")) {
      this.ruleForm.password = localStorage.getItem("paw");
      this.ruleForm.name = localStorage.getItem("name");
      this.remember = true;
    }
    var obj = new WxLogin({
      id: "login_container",
      appid: "wx2d6104cdf3436085",
      scope: "snsapi_login",
      redirect_uri: encodeURI("http://mbox.91s2b2c.com/login"),
      state: "",
      style: "",
      href: "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDE2MHB4O30=",
    });
    const params = new URLSearchParams(window.location.search);
    const paramValue = params.get("code");
    // 扫码成功 获取code
    if (paramValue) {
      this.agreementFn().then((res) => {
        if (res) {
          loginScan({ code: paramValue }).then((res) => {
            if (res.code == 0) {
              localStorage.setItem("token", res.data.token);
              if (res.data.isBindBaseInfo) {
                localStorage.setItem(
                  "userinfo",
                  JSON.stringify(res.data.userinfo)
                );
                localStorage.setItem("id", res.data.id);
                localStorage.setItem("platform", res.data.platform);
                this.$message.success({
                  duration: 2000,
                  message: "登录成功!",
                });
                this.$router.push({
                  path: "/background",
                });
              } else {
                this.codeShow = 3;
                this.$message.warning({
                  duration: 2000,
                  message: "请完善信息!",
                });
              }
            } else {
              this.$message.error({
                duration: 2000,
                message: res.message,
              });
            }
          });
        }
      });
    }
  },
};
</script>     

<style lang="scss" scoped>
.codeCard {
  padding: 50px;
  width: 320px;
  height: 368px;
  box-shadow: 0px 3px 10px #409eff;
  h2 {
    text-align: center;
    margin-bottom: 15px;
  }
}
::v-deep .docx-wrapper {
  background-color: #fff;
  .docx {
    box-shadow: none;
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
  }
}

::v-deep .el-input-group__append button {
  font-weight: 600;
}
.preview {
  width: 100%;
}
.QR {
  img {
    width: 200px;
    height: 200px;
    display: block;
    margin: 0 auto;
  }
  p {
    text-align: center;
    margin-top: 10px;
  }
}
::v-deep .el-input-group__append {
  cursor: pointer;
  background-color: #fff;
  color: #409eff;
  font-weight: 600;
}
.login {
  background: rgb(194, 176, 223);
  background: linear-gradient(
    270deg,
    rgba(194, 176, 223, 1) 0%,
    rgb(84, 105, 209) 45%
  );
  border-radius: 20px;
  color: #fff;
  width: 100%;
  height: 40px;
  font-weight: 600;
}
::v-deep .el-tabs__content {
  height: 270px;
}
.agreement {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
.forget {
  color: rgb(84, 105, 209);
  text-align: center;
  font-weight: 600;
  cursor: pointer;
}

.box {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .card {
    padding: 50px;
    width: 320px;
    height: 31 0px;
    box-shadow: 0px 3px 10px #409eff;
    ::v-deep .el-tabs__nav-wrap::after {
      width: 0;
    }
    ::v-deep .el-tabs__active-bar {
      width: 0 !important;
    }
  }
  > div {
    h1 {
      font-size: 40px;
    }

    width: 1200px;
  }
  .titleBox {
    text-align: center;
    p {
      font-size: 18px;
      margin-top: 20px;
    }
    img {
      width: 500px;
    }
    .titleTop{
      width: 360px;
    }
  }
}
</style>